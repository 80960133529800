import React from 'react'
import { GoogleLogin } from 'react-google-login'
import { useDispatch } from 'react-redux'
import { tryLogin } from '../auth.redux'
import { makeStyles } from '@material-ui/core/styles'
import { GOOGLE_CLIENT_ID } from '../../../const'

const useStyles = makeStyles(() => ({
  loginButton: {
    marginTop: '35px',
    textAlign: 'center',
  },
}))

export const Login = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <div className={classes.loginButton}>
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        onSuccess={response => dispatch(tryLogin(response))}
        cookiePolicy={'single_host_origin'}
      />
    </div>
  )
}
