import axios from 'axios'
import { tryP } from 'fluture'
import { API_URL, API_USER_ID } from '../../const'

export const getToken = () =>
  tryP(() =>
    axios({
      method: 'POST',
      url: '/api/auth/token',
      baseURL: API_URL,
      headers: {
        contentType: 'application/x-www-form-urlencoded',
      },
      data: {
        secret: API_USER_ID,
      },
    })
  )
