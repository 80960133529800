import { tryLogin, loginSuccess, refreshToken, updateToken, logout, setRedirectPath } from './auth.redux'
import { displayToast, SEVERITY } from '../toast/toast.redux'
import { getToken } from './auth.io'
import { TTL, ALLOWED_DOMAINS } from '../../const'
import { of } from 'fluture'

const tryLoginEpic = {
  type: tryLogin.toString(),
  do: ({ payload: response }) => {
    if (ALLOWED_DOMAINS.every(domain => !response.profileObj.email.includes(domain))) {
      return of(
        displayToast({
          message: 'Your Google account is unauthorized from accessing the mobile app CMS',
          severity: SEVERITY.WARNING,
        })
      )
    }

    return getToken().map(res =>
      loginSuccess({
        userInfo: {
          ...response.profileObj,
          loggedInDate: new Date().toISOString(),
          expiryDate: new Date(Date.now() + TTL).toISOString(),
        },
        token: res.data.token,
      })
    )
  },
}

const refreshTokenEpic = {
  type: refreshToken.toString(),
  do: (_, { auth }) => (auth.authenticated ? getToken().map(res => updateToken(res.data.token)) : of()),
}

const logoutEpic = {
  type: logout.toString(),
  do: () => {
    return of(setRedirectPath('/login'))
  },
}

export default [tryLoginEpic, refreshTokenEpic, logoutEpic]
