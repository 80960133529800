import React from 'react'
import { Field, getFormValues } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, MenuItem, Button } from '@material-ui/core'
import MovieIcon from '@material-ui/icons/Movie'
import { PanelAction, INTERNAL_VIEW, PANEL_SIZE, PANEL_TYPE } from '../../page.type'
import { reinitField } from '../../page.redux'
import Input from '../../../reusable/input'
import Checkbox from '../../../reusable/checkbox'
import radioButtons from '../../../reusable/radioButtons'
import ImageUrlField from '../../../reusable/imageUrlField'
import { openModal } from '../../../modal/modal.redux'
import { FORM_NAMES } from '../../../modules.constants'
import _ from 'lodash'

export const usePanelStyles = makeStyles(() => ({
  gridItemWithPadding: {
    height: '72px',
    padding: '16px 12px 0 12px',
  },
  subGridItem: { paddingRight: '16px' },
  colorInput: {
    '&>div>input[type=color]': {
      height: '2em',
      padding: '12px',
    },
  },
  previewButton: { height: '100%' },
}))

/**
 * Render Video field with customizable label, field name, and preview button
 *
 * @param {Object} params
 * @param {number} params.panel Panel
 * @param {string} params.videoId Video ID
 * @param {string} params.label Label
 * @param {string} params.fieldName Field name
 */
export const VideoIdField = ({ panel, videoId, label, fieldName, hasSoundEnabled }) => {
  const classes = usePanelStyles()
  const dispatch = useDispatch()

  return (
    <Grid className={classes.gridItemWithPadding} container justify="space-between">
      <Grid className={classes.subGridItem} item xs={9}>
        <Field
          component={Input}
          onChange={() => dispatch(reinitField({ panel, field: 'isAltImageVisible', value: false }))}
          label={label}
          name={`${panel}.${fieldName}`}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          fullWidth
          size="large"
          onClick={() =>
            dispatch(
              openModal({
                textHeader: 'Video preview',
                videoPreview: videoId,
                maxWidth: 'sm',
                autoClose: true,
                hasSoundEnabled: hasSoundEnabled,
              })
            )
          }
          variant="contained"
          color="primary"
          disabled={!videoId}
          className={classes.previewButton}
          startIcon={<MovieIcon />}
        >
          Preview
        </Button>
      </Grid>
    </Grid>
  )
}

/**
 * Render color picker field with customizable label and field name
 * and editable hexadecimal value
 *
 * @param {Object} params
 * @param {number} params.panel Panel
 * @param {string} params.label Label
 * @param {string} params.fieldName Field name
 */
export const ColorPickerField = ({ panel, label, fieldName }) => {
  const classes = usePanelStyles()

  return (
    <Grid className={classes.gridItemWithPadding} container justify="space-between">
      <Grid className={classes.subGridItem} item xs={9}>
        <Field
          className={classes.colorInput}
          component={Input}
          label={label}
          type="color"
          maxLength={7}
          name={`${panel}.${fieldName}`}
        />
      </Grid>
      <Grid item xs={3}>
        <Field component={Input} label={label} name={`${panel}.${fieldName}`} />
      </Grid>
    </Grid>
  )
}

export const EmbeddedText = ({ panel }) => {
  const classes = usePanelStyles()

  return (
    <>
      <Grid className={classes.gridItemWithPadding} item>
        <Field component={Input} label="Text" name={`${panel}.panelText`} />
      </Grid>
      <Grid className={classes.gridItemWithPadding} item>
        <Field component={Input} select label="Text Position" name={`${panel}.textPosition`}>
          <MenuItem value="left">Left</MenuItem>
          <MenuItem value="center">Center</MenuItem>
          <MenuItem value="right">Right</MenuItem>
        </Field>
      </Grid>
      <Grid className={classes.gridItemWithPadding} item>
        <Field component={Input} select label="Text Style" name={`${panel}.textStyle`}>
          <MenuItem value="light">Light</MenuItem>
          <MenuItem value="dark">Dark</MenuItem>
        </Field>
      </Grid>
    </>
  )
}

export const GeneralInfos = ({ panel, locale, actionType, background, imageUrl, panelType }) => {
  const pageFormValues = useSelector(state => getFormValues(FORM_NAMES.PAGE)(state))
  const panelValues = _.get(pageFormValues, `${locale}.${panel}`)
  const classes = usePanelStyles()
  const dispatch = useDispatch()

  return (
    <>
      <Grid className={classes.gridItemWithPadding} item>
        <Field
          component={Input}
          onChange={() => dispatch(reinitField({ panel, field: 'actionTarget' }))}
          select
          label="Action"
          name={`${panel}.actionType`}
        >
          <MenuItem value={PanelAction.PRODUCT}>Product</MenuItem>
          <MenuItem value={PanelAction.CATEGORY}>Category</MenuItem>
          <MenuItem value={PanelAction.INTERNAL_VIEW}>Internal view</MenuItem>
          <MenuItem value={PanelAction.EXTERNAL_VIEW}>External view</MenuItem>
        </Field>
      </Grid>
      {renderActionTarget(actionType, panel, classes, panelValues, imageUrl)}
      <Grid className={classes.gridItemWithPadding} item>
        <Field
          component={radioButtons}
          onChange={event => {
            dispatch(
              reinitField({
                panel,
                field: 'backgroundColor',
                value: event.target.value === 'backgroundColor' ? '#000000' : null,
              })
            )
            dispatch(
              reinitField({ 
                panel, 
                field: 'imageUrl',
                value: panelValues.imageUrl
              }))
            dispatch(
              reinitField({ 
                panel, 
                field: 'videoId',
                value: panelValues.videoId
              }))
          }}
          name={`${panel}.background`}
          values={[
            { value: 'imageUrl', label: 'Image' },
            { value: 'backgroundColor', label: 'Background color' },
            { value: 'videoId', label: 'Video' },
          ]}
        />
      </Grid>
      {renderRadioButtonActions(background, panelType, classes, panel, panelValues, imageUrl)}
      <Grid className={classes.gridItemWithPadding} item>
        <Field component={Checkbox} label="Has embedded text?" name={`${panel}.hasEmbeddedText`} />
      </Grid>
      {panelType === PANEL_TYPE.FULL_PANEL &&
        <Grid className={classes.gridItemWithPadding} item>
          <Field
            component={Input}
            onChange={() => dispatch(reinitField({ panel, field: 'panelSize' }))}
            select
            label="Size"
            name={`${panel}.panelSize`}
          >
            <MenuItem value={PANEL_SIZE.EXTRA_SMALL}>Extra Small</MenuItem>
            <MenuItem value={PANEL_SIZE.SMALL}>Small</MenuItem>
            <MenuItem value={PANEL_SIZE.MEDIUM}>Medium</MenuItem>
            <MenuItem value={PANEL_SIZE.LARGE}>Large</MenuItem>
          </Field>
        </Grid>
      }
    </>
  )
}

export const renderActionTarget = (actionType, panel, classes) => {
  switch (actionType) {
    case PanelAction.PRODUCT:
      return (
        <Grid className={classes.gridItemWithPadding} item>
          <Field component={Input} label="Product ID" name={`${panel}.actionTarget`} />
        </Grid>
      )
    case PanelAction.CATEGORY:
      return (
        <Grid className={classes.gridItemWithPadding} item>
          <Field component={Input} label="Category ID" name={`${panel}.actionTarget`} />
        </Grid>
      )
    case PanelAction.INTERNAL_VIEW:
      return (
        <Grid className={classes.gridItemWithPadding} item>
          <Field component={Input} select label="Internal Link" name={`${panel}.actionTarget`}>
            <MenuItem value={INTERNAL_VIEW.GIFT_CARD}>Gift Card</MenuItem>
            <MenuItem value={INTERNAL_VIEW.LOYALTY}>Loyalty</MenuItem>
            <MenuItem value={INTERNAL_VIEW.COUPON}>Coupon</MenuItem>
            <MenuItem value={INTERNAL_VIEW.STORE_FINDER}>Store Finder</MenuItem>
            <MenuItem value={INTERNAL_VIEW.MY_CLOSET}>My Closet</MenuItem>
          </Field>
        </Grid>
      )
    case PanelAction.EXTERNAL_VIEW:
      return (
        <Grid className={classes.gridItemWithPadding} item>
          <Field component={Input} label="External Link" name={`${panel}.actionTarget`} />
        </Grid>
      )
    default:
      return null
  }
}

export const renderRadioButtonActions = (background, panelType, classes, panel, panelValues, imageUrl) => {
  switch (background){
    case 'imageUrl':
      return (
        <Grid className={classes.gridItemWithPadding} container justify="space-between">
          <ImageUrlField label="Image URL" fieldName={`${panel}.imageUrl`} imageUrlValue={imageUrl} />
        </Grid>
      )
    case 'backgroundColor':
      return (
        <ColorPickerField panel={panel} label="Background Color" fieldName="backgroundColor" />
      )
    case 'videoId':
      return (
        <>
          {panelType === PANEL_TYPE.CAROUSEL && panelValues &&
            <VideoIdField 
              panel={panel}
              videoId={panelValues.videoId}
              label="Video ID"
              fieldName="videoId"
              hasSoundEnabled={false}
            /> 
          }
        </>
      )
    default:
      return null
  }
}
